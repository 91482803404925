<template>
  <div style="background-color: #f5f5f5">
    
    <el-row class="cc">
        <el-col :span="16" :offset="4">
          <div class="top_dh">
            <p class="top_navigation"><span @click="$router.push('index')">首页</span> &gt; <span @click="changeNav(1)">磐石产品</span> &gt; <span @click="changeNav(1)">磐石云桌面</span> &gt; <span>应用上云</span></p>
          </div>
          <p class="IntroductionTitle">概述</p>
        </el-col>
      </el-row>

      <el-row class="Introduction cw">
        <el-col :span="16" :offset="4" style="margin-bottom: 20px;">
          <div class="contectBox">
            <div class="contectimg">
              <img src="@/assets/panshProduct/application/sy1.png" />
            </div>
            <div class="IntroductionDesc" style="overflow:hidden;" >
              <h6>应用上云</h6>
              <br>
              <p>
                磐石高价值应用上云服务平台是面向工程设计和工程仿真类应用场景专属打造的高价值服务平台，是“软件即服务”理念在高端设计应用领域的具体落地，该平台全面兼容当下主流基础计算资源，更加提供了与云平台全面适配解决方案，实现工程设计等专业应用许可与硬件资源的高效共享，有效保护了投资。
                <br/>
                <br/>
                &emsp;&emsp;磐石高价值应用上云服务平台实现将专业软件使用许可通过应用发布等组件以“许可服务”方式在云数据中心进行托管，并支持进行统一管理、集中控制和按需发布等功能，有效解决了专业设计类软件资源共享难题，提高了专业应用使用效率，最大限度拓展了专业软件应用场景，为用户提供了可与专业图形工作站相媲美的操作体验。
              </p>
              
            </div>

          </div>

        </el-col>
      </el-row>

<!--    主要功能-->
    <el-row>
      <el-col :span="16" :offset="4" style="padding-bottom:2%;">
        <p class="IntroductionTitle mt">产品功能</p>
        <div style="margin-top:2%;">
          <div v-for="(item,index) in proFunction" :key="index" class="proFunctionBox">
            <div class="proFunctionImg"><img v-lazy="item.imgSrc" width="90%"></div>
            <div class="proFunctionTitle">{{item.title}}</div>
            <div class="proFunctionDesc"><p>{{item.desc}}</p></div>
          </div>
        </div>
      </el-col>
    </el-row>
    
<!--    产品特点-->
   <el-row>
      <el-col :span="16" :offset="4">
        <p class="IntroductionTitle mt">产品特色</p>
        <div class="mt" style="margin-top:2%;">
          <div class="frameworkList" style="border-top-left-radius: 0;border-top-right-radius: 0;padding-top: 0">
            <div>
              <img src="@/assets/panshProduct/cloudDesktop/td1.png" width="100%">
            </div>
            <div class="proChara">
              <ul>
                <li>资源统一管理，按需发布</li>
                <li>闲置资源及时回收</li>
                <li>专业设计软件许可以服务方式发布</li>
              </ul>
              <ul>
                <li>媲美工作站的用户体验</li>
                <li>完美的软件兼容性</li>
                <li>广泛的外设支持</li>
                <li>成熟的vGPU支持</li>
              </ul>
              <ul>
                <li>任意时间、任意地点、使用任意企业应用</li>
                <li>全面适配麒麟、统信等国产操作系统 </li>
              </ul>
              <ul>
                <li>统一域名访问，资源共享</li>
                <li>多区域协同办公</li>
              </ul>
              <ul>
                <li>合理利用虚拟化资源，节省硬件投资</li>
                <li>统一运维管理，节约人力成本</li>
              </ul>
              <ul>
                <li>统一管理平台，管理、交付、监控于一体</li>
                <li>定制化云端管理功能；自动运维</li>
              </ul>
              <div class="clearfix"></div>
            </div>
          </div>
        </div>
      </el-col>
    </el-row>

<!--    应用场景-->
    <el-row>
      <el-col :span="16" :offset="4">
        <p class="IntroductionTitle mt">应用场景</p>
        <div class="frameworkList">
          <img src="@/assets/panshProduct/cloudDesktop/cj1.png" />
          <p class="secenp">&emsp;&emsp;磐石高价值应用上云服务平台面向科研院所、机械制造、建筑设计、影视制作、军工等高端设计应用领域，基于现有主流基础计算资源，将高价值设计类应用软件许可池化并以服务方式面向用户发布，既充分利用基础设施资源投入，又有效缓解设计应用许可不足，同时兼顾数据安全和数据保护等功能。</p>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  name: "cloudDesktop",
  data(){
    return{
      proFramework:[{title:'逻辑架构',imgSrc:require('@/assets/panshProduct/application/jiagou01.png')}],
      proFunction:[
        {title:'安全接入',desc:'磐石高价值应用上云服务平台安全网关支持多种身份认证策略，提供双因子、磐石盾、量子密钥等身份认证方式，保证用户登录安全。\r\n安全网关提供统一服务地址和接入端口，与服务发布平台网络隔离，支持用户灵活访问，有效兼顾生产效率和安全举措。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l1.png'),},
        {title:'应用商店',desc:'磐石高价值应用上云服务平台将所有高价值应用软件以服务方式发布，并通过“应用商店”对所有服务进行集中管理，用户可根据服务类别、使用频率等订阅相应应用服务。\n应用商店提供应用服务统计功能，可根据应用申请使用频率、时长等指标参数进行主动统计，为应用综合管理提供决策依据。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l7.png'),},
        {title:'资源申请',desc:'许可服务作为有限资源，系统支持统一管理并提供资源申请流程，用户可按需发起资源使用请求，审批通过后系统主动释放资源并对资源使用情况进行跟踪，主动追踪许可服务使用效率，闲置资源及时释放，确保资源服务价值最大化。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l8.png'),},
        {title:'资源管理',desc:'磐石高价值应用上云服务平台支持对基础计算及存储资源进行统一管理，管理员可通过统一访问界面实现对后台资源的统筹把握，全面监控资源占用及使用效率，有效跟踪服务运行与后台资源匹配效果，同时支持动态资源调整，并为最佳资源服务匹配比例提供科学依据。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l3.png'),},
        {title:'资源交付',desc:'磐石高价值应用上云服务平台交付管理组件承担服务请求处理、用户授权和应用发布等功能，通过友好可视化页面支持对资源、交付组、服务列表、发布策略等进行配置管理。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l2.png'),},
        {title:'运维监控',desc:'磐石高价值应用上云服务平台提供主动运维服务组件，系统会主动跟踪发布服务运行情况，并及时收集影响体验信息以及动态监控客户端运行状况，必要时支持主动干预并支持预警功能，极大丰富了运维手段和提升了运维效率。',imgSrc:require('@/assets/panshProduct/cloudDesktop/l4.png'),},
      ],
      descShow:true,
    }
  },
  created(){
    if (this._isMobile()) {
      // 跳转手机页面
      this.$router.push('cloudApplicationMobile')
    }
  },
  methods:{
    // 判断移动|PC端
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
    changeNav(navNum){
      this.$store.dispatch('changeShow',navNum)
    },
  }
}
</script>

<style scoped>
/*测试代码*/
.body{
  background-color: #f5f5f5;
  z-index: -99;
  height: 3000px;
  margin-top: 150px;
}
/* 通用样式 */
li{
  list-style: none;
}
/* 页面样式 */
/* 导航栏 */
.mt{margin-top: 3%;}

.top_dh{
  margin-bottom: 20px;
}
.top_navigation{
  margin-top: 20px;
}
.top_navigation span{
  font-size: 16px;
  color: hsl(0, 0%, 40%);
  cursor: pointer;
  transition: all 0.3s;
}
.top_navigation span:hover{
  color: #e40f0f;
}
/*产品概述*/
.contectimg{
  width: 39.5%;
  float: left;
  margin-right: 5%;
  margin-bottom: 10px;
  cursor: pointer;
  position: relative;
padding-top: 50px;
}
.contectimg img{
  width: 100%;
}
.cc{background: #f5f5f5;padding-bottom: 30px;}
.cw{background: #fff;}
.rightbtn{text-align: right;}
.mt{margin-top: 3%;}
.contectBox{
  margin-top: 30px;
}
.Introduction{
  position: relative;
}
.IntroductionTitle{
  border-left: 6px solid #e40f0f;
  line-height: 50px;
  font-size: 32px;
  padding-left: 30px;
}
.IntroductionDesc{
  font-size: 18px;
  color: #888;
  line-height: 31px;
}
.IntroductionDesc h6{
  font-size: 26px;
  line-height: 40px;
  font-weight: bold;
  color: #000;
}
.IntroductionDesc p{
  font-size: 16px;
  line-height: 28px;
  text-indent: 2em;
  text-align: justify;
}
/*产品架构区块*/
.frameworkList{
  text-align: center;
  background-color: #fff;
  margin-top:2%;
  padding-top: 2%;
  padding-bottom: 2%;
  /* margin: 0 12.5% 3% 12.5%;
  padding: 44px 0 48px 0; */
}
.frameworkList > img{
  width: 90%;
  margin-top: 2%;
}
.currencyLittleTit{
  font-size: 26px;
  text-align: left;
  color: #101010;
  padding-left: 5%;
}
/*产品特点*/
.proChara{
  text-align: left;
  width: 93%;
  margin-left: 7%;
}
.proChara ul{
  padding-right: 20px;
  float: left;
}
.clearfix{
  clear: both;
}
.proChara ul:first-child{
  width: 15.5%;
}
.proChara ul:nth-child(2){
  width: 14.5%;
}
.proChara ul:nth-child(3){
  width: 13%;
}
.proChara ul:nth-child(4){
  width: 14%;
}
.proChara ul:nth-child(5){
  width: 13%;
}
.proChara ul:nth-child(6){
  width: 11%;
}
.proChara li{
  list-style: disc;
  line-height: 24px;
  text-align: justify;
  color:#888;
}
/* 核心技术 */
.hxjs{
  position: relative;
}
.hxjs img{
  width: 100%;
}
.hxjs p{
  position: absolute;
  left: 5%;
  width: 35%;
  text-indent: 2em;
  text-align: justify;
  color:#888;
}
.hxjs p:nth-child(3){
  top: 30%;
}
.hxjs p:nth-child(4){
  top: 52%;
  display: none;
}
.hxjs h6{
  position: absolute;
  left: 5%;
  top: 10%;
  font-size: 30px;
  font-weight: normal;
}
/*产品功能*/
.proFunctionBox{
  background-color: #fff;
  width: 33.333%;
  float: left;
  padding-top: 3%;
  padding-bottom: 2%;
}
.proFunctionBox img{
  width: 84px;
  height: 80px;
}
.proFunctionTitle{
  font-size: 24px;
  color: #101010;
  line-height: 53px;
  text-align: center;
}
.proFunctionDesc p{
  font-size: 16px;
  color: #888;
  line-height: 24px;
  height: 240px;
  padding: 5% 12%;
  white-space: break-spaces;
  text-align: justify;
}
.proFunctionImg{
  text-align: center;
}
.secenp{
  font-size: 16px;
  color: #888888;
  width: 90%;
  margin: 0 auto;
  text-align: left;
  margin-top: 2%;
}

.p3{display: none;}
@media screen and (min-width: 1200px){
  .proChara{width: 97%;}
}
@media screen and (min-width: 1340px){
  .p3{display: block;}
}
@media screen and (min-width: 1550px){
  .proChara{width: 100%;}
  .hxjs p:nth-child(4){display: block;}
  .proChara li{line-height: 30px;}
}
</style>